<template>
    <chat
        :initiateSubmit='initiateSubmit'
        fallbackRoute='/'
    />
</template>

<script lang='ts'>
import { setSubmitting } from '@/Chat/module';
import { Response } from '@/ts/interfaces/Question';
import { toast } from '@/ts/state/State';
import { defineComponent } from '@vue/runtime-core';
import Chat from '../../Chat/index.vue';
import { setToast } from '@/store';

export default defineComponent({
    components: { Chat },
    methods: {
        async initiateSubmit(responses: Response[]){
            const { questionnaireMetadata } = this.$store.state.chat!;

            const finalize = (toast: toast) => {
                setSubmitting();
                setToast(toast);
            }
            
            try{
                setSubmitting();
                const startTime = Date.now();
                const { id, time } = questionnaireMetadata!;
                await this.$request(`/respond/${id}/${time}`, 'put', responses);
                setTimeout(() => {
                    this.$router.push('/');
                    finalize({ text: 'התשובות שלך נשמרו בהצלחה. ניתן לצפות בהן תחת "פרמטרים ושאלות למעקב"', type: 'success' });
                }, 800 - (Date.now() - startTime));
            }catch(error){
                console.error(error);
                finalize({ text: 'אירעה תקלה. נסו שנית, ועדכנו אותנו אם התקלה חוזרת על עצמה', type: 'error' });
            }
		}
    }
});
</script>